import React, { createContext, useCallback, useReducer, ReactNode, FC } from 'react'
import { Common } from '~/types'

type Props = {
  children?: ReactNode
}

export type PropsEditProps = {
  id: number
  type: Common.TypePopup
  activeRecord?: boolean
  updateData: () => void
}

export type PropsOfferPopup = {
  type: 'delete' | 'resign'
  handleAgreePopup: () => void
  handleDisagreePopup: () => void
  title: string
}

export type PropsAgreeOfferPopup = {
  dataRecord: any
  updateData: () => void
}

enum ActionType {
  ADD_POPUP_OPEN = 'ADD_POPUP_OPEN',
  ADD_POPUP_CLOSE = 'ADD_POPUP_CLOSE',
  ADD_POPUP_OPEN_RESIGN = 'ADD_POPUP_OPEN_RESIGN',
  ADD_POPUP_OPEN_UPDATE_FUNC = 'ADD_POPUP_OPEN_UPDATE_FUNC',
  EDIT_POPUP_CLOSE = 'EDIT_POPUP_CLOSE',
  EDIT_POPUP_OPEN = 'EDIT_POPUP_OPEN',
  OFFER_POPUP_CLOSE = 'OFFER_POPUP_CLOSE',
  OFFER_POPUP_OPEN = 'OFFER_POPUP_OPEN',
  OFFER_POPUP_AGREE = 'OFFER_POPUP_AGREE',
}

type PopupState = {
  visible: boolean
  cellData?: any
  dataRecord?: any
  updateData: () => void
  resign?: boolean
}

type OfferPopupState = {
  visible: boolean
  type: 'delete' | 'resign'
  handleAgreePopup: () => void
  handleDisagreePopup: () => void
  title: string
}

type EditPopupState = {
  visible: boolean
  type?: Common.TypePopup
  id?: number
  activeRecord?: boolean
  updateData: () => void
}

type ReducerState = {
  addPopup: PopupState
  offerPopup: OfferPopupState
  editPopup: EditPopupState
}

type ReducerAction = {
  type: ActionType
  payload?: any
}

type PopupContextState = {
  popupState: ReducerState
  addPopupOpen: (props?: Partial<PopupState>) => void
  addPopupClose: () => void
  editPopupOpen: (payload: PropsEditProps) => void
  editPopupClose: () => void
  offerPopupOpen: (payload: PropsOfferPopup) => void
  offerPopupClose: () => void
  offerPopupAgree: (payload: PropsAgreeOfferPopup) => void
  addPopupUpdateFunc: (updateData: () => void) => void
}

const initReducerState = (): ReducerState => ({
  addPopup: {
    visible: false,
    updateData: () => null,
    resign: false,
  },
  offerPopup: {
    visible: false,
    type: 'resign',
    handleAgreePopup: () => null,
    handleDisagreePopup: () => null,
    title: '',
  },
  editPopup: {
    visible: false,
    updateData: () => null,
  },
})

const addPopupReducer = (state: PopupState, action: ReducerAction): PopupState => {
  switch (action.type) {
    case ActionType.ADD_POPUP_OPEN_UPDATE_FUNC:
      return { ...state, updateData: action.payload.updateData }
    case ActionType.ADD_POPUP_OPEN:
      return {
        ...state,
        visible: true,
        cellData: action.payload.cellData,
        dataRecord: action.payload.dataRecord,
        resign: false,
      }
    case ActionType.ADD_POPUP_OPEN_RESIGN:
      return {
        ...state,
        visible: true,
        cellData: action.payload.cellData,
        dataRecord: action.payload.dataRecord,
        updateData: action.payload.updateData,
        resign: true,
      }
    case ActionType.ADD_POPUP_CLOSE:
      return {
        ...state,
        visible: false,
        cellData: null,
        dataRecord: null,
        updateData: () => null,
        resign: false,
      }
    default:
      return state
  }
}

const offerPopupReducer = (state: OfferPopupState, action: ReducerAction): OfferPopupState => {
  switch (action.type) {
    case ActionType.OFFER_POPUP_OPEN:
      return {
        ...state,
        visible: true,
        type: action.payload.type,
        handleAgreePopup: action.payload.handleAgreePopup,
        handleDisagreePopup: action.payload.handleDisagreePopup,
        title: action.payload.title,
      }
    case ActionType.OFFER_POPUP_CLOSE:
      return {
        ...state,
        visible: false,
        type: 'resign',
        handleAgreePopup: () => null,
        handleDisagreePopup: () => null,
        title: '',
      }
    default:
      return state
  }
}

const editPopupReducer = (state: EditPopupState, action: ReducerAction): EditPopupState => {
  switch (action.type) {
    case ActionType.EDIT_POPUP_OPEN:
      return {
        ...state,
        visible: true,
        type: action.payload.type,
        id: action.payload.id,
        activeRecord: !!action.payload.activeRecord,
        updateData: action.payload.updateData || (() => null),
      }
    case ActionType.EDIT_POPUP_CLOSE:
      return { ...state, visible: false, updateData: () => null }
    default:
      return state
  }
}

const rootReducer = (state: ReducerState, action: ReducerAction): ReducerState => ({
  addPopup: addPopupReducer(state.addPopup, action),
  offerPopup: offerPopupReducer(state.offerPopup, action),
  editPopup: editPopupReducer(state.editPopup, action),
})

export const PopupContext = createContext<PopupContextState>({
  popupState: initReducerState(),
  addPopupOpen: () => null,
  addPopupClose: () => null,
  editPopupOpen: () => null,
  editPopupClose: () => null,
  offerPopupOpen: () => null,
  offerPopupClose: () => null,
  offerPopupAgree: () => null,
  addPopupUpdateFunc: () => null,
})

export const PopupProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initReducerState())

  const addPopupOpen = useCallback((props: Partial<PopupState> = {}) => {
    dispatch({ type: ActionType.ADD_POPUP_OPEN, payload: props })
  }, [])

  const addPopupClose = useCallback(() => {
    dispatch({ type: ActionType.ADD_POPUP_CLOSE })
  }, [])

  const addPopupUpdateFunc = useCallback((updateData: () => void) => {
    dispatch({ type: ActionType.ADD_POPUP_OPEN_UPDATE_FUNC, payload: { updateData } })
  }, [])

  const editPopupOpen = useCallback((payload: PropsEditProps) => {
    dispatch({ type: ActionType.EDIT_POPUP_OPEN, payload })
  }, [])

  const editPopupClose = useCallback(() => {
    dispatch({ type: ActionType.EDIT_POPUP_CLOSE })
  }, [])

  const offerPopupOpen = useCallback((payload: PropsOfferPopup) => {
    dispatch({ type: ActionType.OFFER_POPUP_OPEN, payload })
  }, [])

  const offerPopupClose = useCallback(() => {
    dispatch({ type: ActionType.OFFER_POPUP_CLOSE })
  }, [])

  const offerPopupAgree = useCallback((payload: PropsAgreeOfferPopup) => {
    dispatch({ type: ActionType.ADD_POPUP_OPEN_RESIGN, payload })
    dispatch({ type: ActionType.OFFER_POPUP_CLOSE })
  }, [])

  return (
    <PopupContext.Provider
      value={{
        popupState: state,
        addPopupOpen,
        addPopupClose,
        addPopupUpdateFunc,
        editPopupOpen,
        editPopupClose,
        offerPopupOpen,
        offerPopupClose,
        offerPopupAgree,
      }}
    >
      {children}
    </PopupContext.Provider>
  )
}
