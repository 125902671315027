import CustomStore from 'devextreme/data/custom_store'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { Sto } from '~/api'
import notify from 'devextreme/ui/notify'
import { AuthContext } from '~/context'
import { Common } from '~/types'
import ReportBase, { PropsComponet } from '~/ui/atoms/ReportBase'

import {
  DataGrid,
  Column,
  Summary,
  TotalItem,
  Sorting,
  Scrolling,
  Paging,
  Button,
  Editing,
  Export,
  FilterRow,
  Lookup,
  RequiredRule,
} from 'devextreme-react/data-grid'
import { Model, Status as ModelStatus, Records } from '~/api/records'
import { getActiveRecords } from '../services'
import { onDbClick } from '~/routes/Reports/service'
import { PopupContext } from '~/context/PopupContext'
import { bemHelper } from '~/libs'

import './style.scss'
import {
  handleDownload,
  isButtonVisible,
  handleDownloadReferral,
  isReferralButtonVisible,
} from '~/utils/downloadDk'
import { CellRenderLink, cellRenderIndex } from '~/utils/reportCellLink'
import { checkPermission } from '~/utils/checkPermission'
import ColumnSelectBox from './atoms/ColumnSelectBox'

let dkId: number | null
let approvedStatusId: number | null

const cn = bemHelper('active-record')

function LightenDarkenColor(color: string, percent: number) {
  var num = parseInt(color, 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt

  return (
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)
}

const rowPreparating = (e: any) => {
  if (e.rowType === 'data') {
    e.rowElement.style.backgroundColor =
      '#' + LightenDarkenColor(e.data.approvedStatusColor.replace('#', '').toLocaleUpperCase(), 25)
  }
}

const OperatorComponent: React.FC<PropsComponet> = memo(({ data, updateData, allSto }) => {
  const { editPopupOpen } = useContext(PopupContext)

  const [sortedData, setSortedData] = useState<Model[]>([])
  const [allStatus, setAllStatus] = useState<ModelStatus[]>([])
  const { authState } = useContext(AuthContext)
  const refInputFile = useRef<HTMLInputElement | null>(null)

  const getAllStatus = async () => {
    const result = await Records.controller.getAllStatus()
    setAllStatus(result)
  }

  const handleChangeDkFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && dkId) {
      const file = e.target.files[0]
      e.target.value = ''
      try {
        if (approvedStatusId !== 6) {
          await Records.controller.changeStatus(dkId, 6)
        }
        await Records.controller.uploadDkPdf(file, dkId)

        notify(
          {
            message: 'Запись успешно обновлена',
            position: 'top center',
          },
          'success',
          3000
        )

        updateData()
      } catch (err: any) {
        notify(
          {
            message: `Упс... Произошла ошибка ${err.message}`,
            position: 'top center',
          },
          'error',
          3000
        )
      }

      dkId = null
      approvedStatusId = null
    }
  }

  const openDialogFunc = (id: number, statusId: number) => {
    refInputFile.current?.click()
    dkId = id
    approvedStatusId = statusId
  }

  const addButtonIsVisible = (e: any) => {
    if (e.row.data.approvedStatusId == 9) {
      return false
    }

    return isButtonVisible(e, true)
  }

  useEffect(() => {
    setSortedData(getActiveRecords(data))
  }, [data])

  useEffect(() => {
    getAllStatus()
  }, [])

  return (
    <div style={{ marginTop: '20px', width: '100%' }}>
      <DataGrid
        dataSource={sortedData}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders
        onRowPrepared={rowPreparating}
        hoverStateEnabled={true}
        className="data-grid-report"
        showColumnLines
        allowColumnReordering={true}
        onCellDblClick={(e) => onDbClick({ e: e, editPopupOpen, activeRecord: true, updateData })}
        noDataText="Нет записей"
      >
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always" />
        <Paging enabled={false} />
        <Editing mode={'cell'} allowUpdating />
        <FilterRow visible={true} applyFilter="auto" />

        <Column
          caption={'№'}
          width={50}
          cellRender={(data: any) =>
            cellRenderIndex({ cellData: data, editPopupOpen, activeRecord: true, updateData })
          }
          alignment="left"
          allowEditing={false}
          name="number"
        />

        <Column
          caption={'№ Заявки'}
          alignment="center"
          dataField="dkId"
          cellRender={(data: any) =>
            CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
          }
          name="orderNumber"
          allowEditing={false}
        />

        <Column type="buttons" width={110} caption="Бланк ДК" name="DownloadButton">
          <Button
            hint="Скачать ДК"
            icon="pdffile"
            visible={(e: any) => isButtonVisible(e)}
            onClick={(e: any) => handleDownload(e.row.data.dkId)}
          />
          <Button
            hint="Добавить ДК"
            cssClass={cn('addDkButton')}
            text={'Добавить'}
            visible={addButtonIsVisible}
            onClick={(e: any) => openDialogFunc(e.row.data.dkId, e.row.data.approvedStatusId)}
          />
        </Column>

        <Column
          dataField="approvedStatusId"
          caption="Статус"
          alignment="center"
          editCellRender={(cell: any) => ColumnSelectBox(cell, updateData, authState.userData)}
          allowEditing={true}
          width={200}
        >
          <Lookup dataSource={allStatus} displayExpr="name" valueExpr="id" />
          <RequiredRule />
        </Column>

        <Column
          caption="Дата, Время"
          dataType="datetime"
          dataField="dateReceiptStart"
          alignment="center"
          allowEditing={false}
          defaultSortOrder="desc"
        />

        <Column caption="TC" alignment="center">
          <Column
            dataField="regNumber"
            caption="Гос.номер"
            alignment="center"
            allowEditing={false}
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />
          <Column
            dataField="dkAutoTypeName"
            caption="Кат"
            allowEditing={false}
            alignment="center"
          />
          <Column
            dataField="brand"
            caption="Марка"
            alignment="center"
            allowEditing={false}
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />

          <Column
            dataField="model"
            caption="Модель"
            allowEditing={false}
            alignment="center"
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />
        </Column>

        {allSto && (
          <Column
            caption="Адрес СТО"
            alignment="center"
            dataField="stoAddress"
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />
        )}

        <Column caption="Клиент" alignment="center">
          <Column
            dataField="clientFio"
            caption="ФИО"
            alignment="center"
            allowEditing={false}
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />
          <Column
            dataField="companyName"
            caption="Компания"
            allowEditing={false}
            alignment="center"
          />
        </Column>

        <Column
          dataField="lineNumber"
          caption={'Линия'}
          width={90}
          visible={authState.userData?.sto.tsLimit > 1}
          allowEditing={false}
          alignment="center"
        />

        <Column
          dataField="agentName"
          caption="Канал"
          allowEditing={false}
          cellRender={(data: any) =>
            CellRenderLink({
              cellData: data,
              editPopupOpen,
              isOperator: true,
              agentId: authState.userData?.agent.id,
            })
          }
          alignment="center"
        />

        <Column
          dataField="payment"
          caption="Оплата"
          dataType="string"
          allowEditing={false}
          alignment="center"
        />
      </DataGrid>

      <input
        type="file"
        accept="application/pdf"
        ref={refInputFile}
        style={{ visibility: 'hidden' }}
        onChange={handleChangeDkFile}
      />
    </div>
  )
})

const AgentComponent: React.FC<PropsComponet> = memo(({ data, updateData }) => {
  const [sortedData, setSortedData] = useState<Model[]>([])
  const { authState } = useContext(AuthContext)
  const { editPopupOpen } = useContext(PopupContext)

  useEffect(() => {
    setSortedData(getActiveRecords(data))
  }, [data])

  return (
    <div style={{ marginTop: '20px', width: '100%' }}>
      <DataGrid
        dataSource={sortedData}
        allowColumnResizing={true}
        columnAutoWidth={true}
        showBorders
        hoverStateEnabled={true}
        onRowPrepared={rowPreparating}
        className="data-grid-report"
        showColumnLines
        allowColumnReordering={true}
        onCellDblClick={(e) => {
          onDbClick({ e: e, editPopupOpen, activeRecord: true, updateData })
        }}
        noDataText="Нет записей"
      >
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always" />
        <Paging enabled={false} />
        <FilterRow visible={true} applyFilter="auto" />

        <Column
          caption={'№'}
          width={50}
          cellRender={(data: any) =>
            cellRenderIndex({ cellData: data, editPopupOpen, activeRecord: true, updateData })
          }
          alignment="left"
          name="number"
        />

        <Column
          caption={'№ Заявки'}
          alignment="center"
          dataField="dkId"
          cellRender={(data: any) =>
            CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
          }
          name="orderNumber"
        />

        <Column type="buttons" caption="Направление\Бланк ДК">
          <Button
            hint="Скачать направление"
            icon="pdffile"
            visible={isReferralButtonVisible}
            onClick={(e: any) => handleDownloadReferral(e)}
          />
          <Button
            hint="Скачать Бланк ДК"
            icon="pdffile"
            visible={(e: any) => isButtonVisible(e)}
            onClick={(e: any) => handleDownload(e.row.data.dkId)}
          />
        </Column>

        <Column
          caption="Дата, Время"
          dataType="datetime"
          dataField="dateReceiptStart"
          defaultSortOrder="desc"
          alignment="center"
        />

        {authState.userData?.roles.includes(Common.SubPermissions.ROLE_BROKER_DIRECTOR) && (
          <Column dataField="staffFio" caption="Сотрудник" alignment="center" />
        )}

        <Column
          caption="СТО"
          alignment="center"
          dataField="stoAddress"
          cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
        />

        <Column caption="Клиент" alignment="center">
          <Column
            dataField="clientFio"
            caption="ФИО"
            alignment="center"
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />
          <Column dataField="clientPhone" caption="Телефон" alignment="center" />
          <Column dataField="companyName" caption="Компания" alignment="center" />
        </Column>

        <Column caption="TC" alignment="center">
          <Column dataField="brand" caption="Марка" alignment="center" />

          <Column dataField="model" caption="Модель" alignment="center" />
          <Column
            dataField="regNumber"
            caption="Гос.номер"
            alignment="center"
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />
        </Column>

        <Column dataField="approvedStatusName" caption="Статус" alignment="center" />
      </DataGrid>
    </div>
  )
})

export const ActiveRecords: React.FC = React.memo(() => {
  const dataSource = new CustomStore({
    key: 'dkId',
    load: async (loadOptions) => {
      const data = await Sto.controller.getOrderInfo({
        date: loadOptions.searchValue.date,
        days: loadOptions.searchValue.days,
      })

      return data
    },
    errorHandler: (error: any) => {
      notify(
        {
          message: `Упс... Произошла ошибка ${error.message}`,
          position: 'top center',
        },
        'error',
        3000
      )
    },
  })

  const { authState } = useContext(AuthContext)

  let Component = AgentComponent

  if (authState.userData?.roles.includes(Common.Permissions.ROLE_AGENT)) {
    Component = AgentComponent
  } else {
    Component = OperatorComponent
  }

  return (
    <ReportBase
      title="Записи на СТО"
      Component={Component}
      dataSource={dataSource}
      startFromMonday
      onlyDayAndWeek
      activeRecord
    />
  )
})
