import React, { useContext } from 'react'

import {
  DataGrid,
  Column,
  Sorting,
  Scrolling,
  Paging,
  Button,
  Export,
  FilterRow,
} from 'devextreme-react/data-grid'

import './styles.scss'
import ReportBase, { PropsComponet } from '~/ui/atoms/ReportBase'
import CustomStore from 'devextreme/data/custom_store'
import { Sto, Records } from '~/api'
import { memo } from 'react'
import { formatDate } from 'devextreme/localization'
import { AuthContext, PopupContext } from '~/context'
import { Common } from '~/types'
import { Model as ModelRecord } from '~/api/records'

import notify from 'devextreme/ui/notify'
import { CellRenderLink, cellRenderIndex } from '~/utils/reportCellLink'
import { getFilteredCommon, onDbClick, onExporting } from '../service'
import { handleDownload, isButtonVisible } from '~/utils/downloadDk'
import { checkPermission } from '~/utils/checkPermission'

const handleDownloadReferral = async (e: any) => {
  try {
    const blob = await Records.controller.downloadReferralToSto(e.row.data.dkId)
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = `Направление №${e.row.data.dkId}.pdf`
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (error: any) {
    notify(
      {
        message: `Упс... Произошла ошибка ${error.message}`,
        position: 'top center',
      },
      'error',
      3000
    )
  }
}

const isReferralButtonVisible = (e: any) => {
  return e.row.data.approvedStatusId === 2
}

const OperatorComponent: React.FC<PropsComponet> = memo(
  ({ data, days, date, title, allSto, updateData }) => {
    const { authState } = useContext(AuthContext)
    const { editPopupOpen } = useContext(PopupContext)

    const saleAmountFormat = {
      style: 'currency',
      currency: 'RUB',
      useGrouping: true,
      minimumSignificantDigits: 1,
    }

    let startDate = new Date(date.getTime())
    let endDate = new Date(date.getTime())

    endDate.setDate(endDate.getDate() + days - 1)

    const onExport = (e: any) => {
      const fileName = `с ${formatDate(startDate, 'dd.MM.yyyy')} по ${formatDate(
        endDate,
        'dd.MM.yyyy'
      )} ${title}`
      onExporting(e, fileName)
    }

    return (
      <>
        <DataGrid
          dataSource={data}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders
          hoverStateEnabled={true}
          className="data-grid-report"
          showColumnLines
          allowColumnReordering={true}
          noDataText="Пусто"
          onExporting={onExport}
          onCellDblClick={(e) => {
            onDbClick({ e: e, editPopupOpen })
          }}
        >
          <Sorting mode="multiple" />
          <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always" />
          <Paging enabled={false} />
          <FilterRow visible={true} applyFilter="auto" />

          <Column
            caption={'№'}
            width={50}
            name="number"
            cellRender={(data: any) => cellRenderIndex({ cellData: data, editPopupOpen })}
            alignment="left"
          />

          <Column
            caption={'№ Заявки'}
            alignment="center"
            dataField="dkId"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
            name="orderNumber"
          />

          <Column
            caption="Адрес СТО"
            alignment="center"
            dataField="stoAddress"
            visible={allSto}
            cellRender={(data: any) =>
              CellRenderLink({ cellData: data, editPopupOpen, activeRecord: true, updateData })
            }
          />

          <Column dataField="lineNumber" caption={'Линия'} width={70} alignment="center" />

          <Column
            caption="Дата, Время"
            dataType="datetime"
            dataField="dateReceiptStart"
            defaultSortOrder="desc"
          />

          <Column caption="TC" alignment="center">
            <Column
              dataField="brand"
              caption="Марка"
              alignment="center"
              cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
            />

            <Column
              dataField="model"
              caption="Модель"
              alignment="center"
              cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
            />
            <Column
              dataField="regNumber"
              caption="Гос.номер"
              alignment="center"
              cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
            />

            <Column dataField="dkAutoTypeName" caption="Кат" alignment="center" />
          </Column>

          <Column caption="Клиент" alignment="center">
            <Column
              dataField="clientFio"
              caption="ФИО"
              alignment="center"
              cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
            />

            <Column dataField="clientPhone" caption="Телефон" alignment="center" />

            <Column dataField="companyName" caption="Компания" alignment="center" />
          </Column>

          <Column
            dataField="priceSto"
            caption="Стоимость"
            dataType="number"
            format={saleAmountFormat}
            alignment="center"
          />

          <Column
            dataField="payment"
            caption="Оплата"
            dataType="string"
            allowEditing={false}
            alignment="center"
          />

          <Column
            dataField="agentName"
            caption="Канал"
            alignment="center"
            cellRender={(data: any) =>
              CellRenderLink({
                cellData: data,
                editPopupOpen,
                isOperator: true,
                agentId: authState.userData?.agent.id,
              })
            }
          />

          <Column dataField="approvedStatusName" caption="Статус" alignment="center" />

          <Column type="buttons" width={110} caption="Бланк ДК">
            <Button
              hint="Скачать ДК"
              icon="pdffile"
              visible={isButtonVisible}
              onClick={(e: any) => handleDownload(e.row.data.dkId)}
            />
          </Column>

          <Export
            enabled={true}
            texts={{
              exportTo: 'hello',
            }}
          />
        </DataGrid>
      </>
    )
  }
)

const AgentComponent: React.FC<PropsComponet> = memo(({ data, days, date, title }) => {
  const { authState } = useContext(AuthContext)

  const saleAmountFormat = {
    style: 'currency',
    currency: 'RUB',
    useGrouping: true,
    minimumSignificantDigits: 1,
  }

  let startDate = new Date(date.getTime())
  let endDate = new Date(date.getTime())

  endDate.setDate(endDate.getDate() + days - 1)
  const { editPopupOpen } = useContext(PopupContext)

  const onExport = (e: any) => {
    const fileName = `с ${formatDate(startDate, 'dd.MM.yyyy')} по ${formatDate(
      endDate,
      'dd.MM.yyyy'
    )} ${title}`
    onExporting(e, fileName)
  }

  return (
    <>
      <DataGrid
        dataSource={data}
        allowColumnResizing={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
        showBorders
        className="data-grid-report"
        showColumnLines
        allowColumnReordering={true}
        noDataText="Пусто"
        onExporting={onExport}
        onCellDblClick={(e) => {
          onDbClick({ e: e, editPopupOpen })
        }}
      >
        <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always" />
        <Paging enabled={false} />
        <FilterRow visible={true} applyFilter="auto" />

        <Column
          caption={'№'}
          width={50}
          alignment="left"
          name={'number'}
          cellRender={(data: any) => cellRenderIndex({ cellData: data, editPopupOpen })}
        />

        <Column
          caption={'№ Заявки'}
          alignment="center"
          dataField="dkId"
          cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          name="orderNumber"
        />

        {authState.userData?.roles.includes(Common.SubPermissions.ROLE_BROKER_DIRECTOR) && (
          <Column dataField="staffFio" caption="Сотрудник" alignment="center" />
        )}

        <Column
          caption="СТО"
          alignment="center"
          dataField="stoAddress"
          cellRender={(data: any) =>
            CellRenderLink({
              cellData: data,
              editPopupOpen,
            })
          }
        />

        <Column
          caption="Дата, Время"
          dataType="datetime"
          alignment="center"
          dataField="dateReceiptStart"
          defaultSortOrder="desc"
        />

        <Column caption="ТС" alignment="center">
          <Column
            dataField="brand"
            caption="Марка"
            alignment="center"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          />
          <Column
            dataField="model"
            caption="Модель"
            alignment="center"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          />
          <Column
            dataField="regNumber"
            caption="Рег.номер"
            alignment="center"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          />
          <Column dataField="dkAutoTypeName" caption="Кат" alignment="center" />
        </Column>

        <Column caption="Клиент" alignment="center">
          <Column
            dataField="clientFio"
            caption="ФИО"
            alignment="center"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          />

          <Column dataField="clientPhone" caption="Телефон" alignment="center" />

          <Column dataField="companyName" caption="Компания" alignment="center" />
        </Column>

        <Column
          dataField="priceSto"
          caption="Стоимость"
          dataType="number"
          format={saleAmountFormat}
          alignment="center"
        />
        <Column
          dataField="priceClient"
          caption="Получено от клиента"
          dataType="number"
          format={saleAmountFormat}
          alignment="center"
        />
        <Column dataField="approvedStatusName" caption="Статус" alignment="center" />

        <Column type="buttons" name="downloadReferral" caption="Направление">
          <Button
            hint="Скачать направление"
            icon="pdffile"
            visible={isReferralButtonVisible}
            onClick={(e: any) => handleDownloadReferral(e)}
          />
        </Column>

        <Column type="buttons" name="downloadDk" width={110} caption="Бланк ДК" alignment="center">
          <Button
            hint="Скачать ДК"
            icon="pdffile"
            visible={isButtonVisible}
            onClick={(e: any) => handleDownload(e.row.data.dkId)}
          />
        </Column>

        <Export enabled={true} />
      </DataGrid>
    </>
  )
})

const CommonReport: React.FC = () => {
  const dataSource = new CustomStore({
    key: 'dkId',
    load: async (loadOptions) => {
      const data = await Sto.controller.getOrderInfo({
        date: loadOptions.searchValue.date,
        days: loadOptions.searchValue.days,
      })

      return getFilteredCommon(data)
    },
    errorHandler: (error: any) => {
      notify(
        {
          message: `Упс... Произошла ошибка ${error.message}`,
          position: 'top center',
        },
        'error',
        3000
      )
    },
  })

  const {
    authState: { userData },
  } = useContext(AuthContext)

  let Component = AgentComponent

  if (checkPermission(userData?.roles, Common.Permissions.ROLE_AGENT)) {
    Component = AgentComponent
  } else {
    Component = OperatorComponent
  }

  return <ReportBase title="Общий отчет" Component={Component} dataSource={dataSource} />
}

export default React.memo(CommonReport)
