import React, { useContext } from 'react'

import {
  DataGrid,
  Column,
  Summary,
  TotalItem,
  Sorting,
  Scrolling,
  Paging,
  Button,
  Editing,
  Export,
  FilterRow,
} from 'devextreme-react/data-grid'

import './styles.scss'
import ReportBase, { PropsComponet } from '~/ui/atoms/ReportBase'
import CustomStore from 'devextreme/data/custom_store'
import { Sto } from '~/api'
import { memo } from 'react'
import { formatDate } from 'devextreme/localization'
import { AuthContext, PopupContext } from '~/context'
import { Model as ModelRecord } from '~/api/records'
import notify from 'devextreme/ui/notify'
import { CellRenderLink, cellRenderIndex } from '~/utils/reportCellLink'
import { customizeTotalPrice, getFilteredCommon, onDbClick, onExporting } from '../service'

const AgentComponent: React.FC<PropsComponet> = memo(({ data, days, date, title }) => {
  const saleAmountFormat = {
    style: 'currency',
    currency: 'RUB',
    useGrouping: true,
    minimumSignificantDigits: 1,
  }

  let startDate = new Date(date.getTime())
  let endDate = new Date(date.getTime())

  endDate.setDate(endDate.getDate() + days - 1)
  const { editPopupOpen } = useContext(PopupContext)

  const calculateProfit = (data: ModelRecord) => {
    return data.priceClient - data.priceSto
  }

  const onExport = (e: any) => {
    const fileName = `с ${formatDate(startDate, 'dd.MM.yyyy')} по ${formatDate(
      endDate,
      'dd.MM.yyyy'
    )} ${title}`
    onExporting(e, fileName)
  }

  return (
    <>
      <DataGrid
        dataSource={data}
        allowColumnResizing={true}
        columnAutoWidth={true}
        hoverStateEnabled={true}
        showBorders
        className="data-grid-report"
        showColumnLines
        allowColumnReordering={true}
        noDataText="Пусто"
        onExporting={onExport}
        onCellDblClick={(e) => {
          onDbClick({ e: e, editPopupOpen })
        }}
      >
        <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always" />
        <Paging enabled={false} />
        <FilterRow visible={true} applyFilter="auto" />

        <Column
          caption={'№'}
          width={50}
          alignment="left"
          name={'number'}
          cellRender={(data: any) => cellRenderIndex({ cellData: data, editPopupOpen })}
        />

        <Column caption="Станция" alignment="center">
          <Column
            dataField="stoNameShort"
            caption="Название"
            alignment="center"
            cellRender={(data: any) =>
              CellRenderLink({
                cellData: data,
                editPopupOpen,
                isOperator: false,
              })
            }
          />

          <Column caption="Адрес" alignment="center" dataField="stoAddress" />
        </Column>

        <Column
          caption="Дата, Время"
          dataType="datetime"
          alignment="center"
          dataField="dateReceiptStart"
          defaultSortOrder="desc"
        />

        <Column caption="ТС" alignment="center">
          <Column
            dataField="brand"
            caption="Марка"
            alignment="center"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          />
          <Column
            dataField="model"
            caption="Модель"
            alignment="center"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          />
          <Column
            dataField="regNumber"
            caption="Рег.номер"
            alignment="center"
            cellRender={(data: any) => CellRenderLink({ cellData: data, editPopupOpen })}
          />
          <Column dataField="dkAutoTypeName" caption="Кат" alignment="center" />
        </Column>

        <Column
          dataField="priceSto"
          caption="Стоимость"
          dataType="number"
          format={saleAmountFormat}
          alignment="center"
        />
        <Column
          dataField="priceClient"
          caption="Клиент оплатил на СТО"
          dataType="number"
          format={saleAmountFormat}
          alignment="center"
        />

        <Column
          name="profit"
          caption="Прибыль"
          dataType="number"
          calculateCellValue={calculateProfit}
          format={saleAmountFormat}
          alignment="center"
        />

        <Column
          dataField="payment"
          caption="Оплата"
          dataType="string"
          allowEditing={false}
          alignment="center"
        />

        <Column dataField="approvedStatusName" caption="Статус" alignment="center" />

        <Summary>
          <TotalItem
            column="priceClient"
            summaryType="sum"
            displayFormat="{0}"
            customizeText={customizeTotalPrice}
            valueFormat={saleAmountFormat}
          />
          <TotalItem
            column="priceSto"
            summaryType="sum"
            customizeText={customizeTotalPrice}
            displayFormat="{0}"
            valueFormat={saleAmountFormat}
          />
          <TotalItem
            column="profit"
            summaryType="sum"
            customizeText={customizeTotalPrice}
            displayFormat="{0}"
            valueFormat={saleAmountFormat}
          />
          <TotalItem
            column="stoNameShort"
            summaryType="sum"
            displayFormat="Итог:"
            alignment="left"
          />
        </Summary>

        <Export enabled={true} />
      </DataGrid>
    </>
  )
})

const AdminReport: React.FC = () => {
  const dataSource = new CustomStore({
    key: 'dkId',
    load: async (loadOptions) => {
      const data = await Sto.controller.getOrderToBigDick({
        date: loadOptions.searchValue.date,
        days: loadOptions.searchValue.days,
      })

      return getFilteredCommon(data)
    },
    errorHandler: (error: any) => {
      notify(
        {
          message: `Упс... Произошла ошибка ${error.message}`,
          position: 'top center',
        },
        'error',
        3000
      )
    },
  })

  let Component = AgentComponent

  return <ReportBase title="Сверочный отчет" Component={Component} dataSource={dataSource} />
}

export default React.memo(AdminReport)
